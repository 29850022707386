<template>
  <div>
    <div class="row twofa-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('TWOFA.TFAUTH')">
          <template v-slot:toolbar> </template>
          <template v-slot:body>
            <b-alert
              show
              variant="warning"
              v-html="$t('HOME.DESC1')"
              v-if="!isTfa"
            ></b-alert>
            <b-alert
              show
              variant="warning"
              v-html="$t('HOME.DESC2')"
              v-if="!isTfa"
            ></b-alert>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('TWOFA.SEL_STATUS')"
                  label-for="select-status"
                >
                  <b-form-select
                    id="select-status"
                    v-model="status.selected"
                    :options="statusData"
                    value-field="value"
                    text-field="name"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <b-button
                  variant="primary"
                  @click="openModal()"
                  style="margin-top: 25px"
                >
                  {{ $t("COMMON.SAVE") }}
                </b-button>
              </div>
            </div>
            <!-- ----------disable modal------------- -->
            <b-modal ref="disableModal" :title="$t('TWOFA.DISABLE_TITLE')">
              <b-container fluid>
                <p>1. {{ $t("TWOFA.DDEC1") }}</p>
                <p>2. {{ $t("TWOFA.DDEC2") }}</p>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label for="Challenge">{{ $t("TWOFA.CHALLENGE") }}:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="Challenge"
                      v-model="$v.form.challenge.$model"
                      :state="validateState('challenge')"
                      :placeholder="$t('TWOFA.CHALLENGE')"
                      aria-describedby="input-challenge-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-challenge-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('disableModal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="disableTfa()">
                  {{ $t("COMMON.SAVE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------enable modal step 1------------- -->
            <b-modal
              ref="enableModal1"
              title="Enable Two Factor Authentication: Step 1"
            >
              <b-container fluid>
                <p>
                  1. {{ $t("TWOFA.DEC1") }}
                  <a href="https://www.authy.com/download" target="_blank">{{
                    $t("TWOFA.AUTHY")
                  }}</a
                  >.
                </p>
                <p>2. {{ $t("TWOFA.DEC2") }}</p>
                <p>3. {{ $t("TWOFA.DEC3") }}</p>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('enableModal1')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="openEnableModal2()">
                  {{ $t("COMMON.NEXT") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------enable modal step 2------------- -->
            <b-modal
              ref="enableModal2"
              title="Enable Two Factor Authentication: Step 2"
            >
              <b-container fluid>
                <b-row>
                  <b-col md="4">
                    <p>1. {{ $t("TWOFA.SCAN_DEC1") }}</p>
                    <p>2. {{ $t("TWOFA.SCAN_DEC2") }}</p>
                    <p>3. {{ $t("TWOFA.SCAN_DEC3") }}</p>
                    <p>4. {{ $t("TWOFA.SCAN_DEC4") }}</p>
                  </b-col>
                  <b-col md="8">
                    <img
                      :src="'data:image/png;base64,' + qrcodeData"
                      class="w-100"
                    />
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label for="Challenge">{{ $t("TWOFA.CHALLENGE") }}:</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="Challenge"
                      v-model="$v.form.challenge.$model"
                      :state="validateState('challenge')"
                      :placeholder="$t('TWOFA.CHALLENGE')"
                      aria-describedby="input-challenge-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-challenge-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('enableModal2')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="enableTfa()">
                  {{ $t("COMMON.SAVE") }}
                </b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required } from "vuelidate/lib/validators";
import JwtService from "@/common/jwt.service";

export default {
  mixins: [validationMixin],
  name: "twofa",
  components: {
    KTPortlet,
  },
  data: () => ({
    status: {
      selected: 0,
    },
    form: {
      challenge: null,
    },
    qrcodeData: "",
  }),
  computed: {
    statusData: function () {
      return [
        { name: this.$t("COMMON.DISABLED"), value: 0 },
        { name: this.$t("COMMON.ENABLED"), value: 1 },
      ];
    },
    isTfa: function () {
      return localStorage.isTfa == 1;
    },
  },
  created() {
    postRequest({
      action: "get2fa",
      token: localStorage.id_token,
    }).then((res) => {
      if (res && res.returncode) {
        this.status.selected = res.returndata["2fa_enabled"];
      } else {
        this.makeToastVariant("danger", res.returndata);
      }
    });
  },
  validations: {
    form: {
      challenge: {
        required,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.TWOFA") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    /*  -----modal close------- */
    modalClose(modalRef) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.form.challenge = null;
      this.$refs[modalRef].hide();
    },

    openModal() {
      if (this.status.selected == 1) {
        this.$refs["enableModal1"].show();
      } else {
        this.$refs["disableModal"].show();
      }
    },

    openEnableModal2() {
      postRequest({
        action: "enable_2fa",
        token: localStorage.id_token,
        step_link: "step0",
      }).then((res) => {
        if (res && res.returncode) {
          this.qrcodeData = decodeURIComponent(res.returndata.qr);
          this.$refs["enableModal2"].show();
          this.modalClose("enableModal1");
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    disableTfa() {
      this.$v.form.$touch();
      if (this.$v.form.challenge.$anyError) {
        return;
      }
      postRequest({
        action: "disable_2fa",
        token: localStorage.id_token,
        twofa_code: this.form.challenge,
      }).then((res) => {
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          JwtService.saveTfaState(0);
          this.modalClose("disableModal");
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    enableTfa() {
      this.$v.form.$touch();
      if (this.$v.form.challenge.$anyError) {
        return;
      }
      postRequest({
        action: "verify_2fa",
        token: localStorage.id_token,
        twofa_code: this.form.challenge,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          JwtService.saveTfaState(1);
          this.modalClose("enableModal2");
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
